exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-projects-index-js": () => import("./../../../src/pages/personal-projects/index.js" /* webpackChunkName: "component---src-pages-personal-projects-index-js" */),
  "component---src-pages-personal-projects-knightstour-js": () => import("./../../../src/pages/personal-projects/knightstour.js" /* webpackChunkName: "component---src-pages-personal-projects-knightstour-js" */),
  "component---src-pages-personal-projects-praatvscode-js": () => import("./../../../src/pages/personal-projects/praatvscode.js" /* webpackChunkName: "component---src-pages-personal-projects-praatvscode-js" */),
  "component---src-pages-personal-projects-website-js": () => import("./../../../src/pages/personal-projects/website.js" /* webpackChunkName: "component---src-pages-personal-projects-website-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-interests-js": () => import("./../../../src/pages/research/interests.js" /* webpackChunkName: "component---src-pages-research-interests-js" */),
  "component---src-pages-research-posters-index-js": () => import("./../../../src/pages/research/posters/index.js" /* webpackChunkName: "component---src-pages-research-posters-index-js" */),
  "component---src-pages-research-posters-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/research/posters/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-research-posters-markdown-remark-parent-file-name-js" */),
  "component---src-pages-research-projects-index-js": () => import("./../../../src/pages/research/projects/index.js" /* webpackChunkName: "component---src-pages-research-projects-index-js" */),
  "component---src-pages-research-publications-index-js": () => import("./../../../src/pages/research/publications/index.js" /* webpackChunkName: "component---src-pages-research-publications-index-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */)
}

